<template>
    <div id="widget-nav">
        <el-row :gutter="20" type="flex" justify="space-between">
            <el-col :span="8"><a class="logo" @click="runInto('Home')">ᕙ(⇀‸↼‶)ᕗ</a></el-col>
            <el-col :span="16">
                <el-menu mode="horizontal" unique-opened :default-active="activeComponent" @select="runInto">
                    <template v-for="(menu, index) in filter(menuList)">
                        <el-menu-item v-if="menu.dest" :index="menu.dest" :key="menu.name + index">{{ menu.name }}</el-menu-item>
                        <el-submenu v-else :index="menu.dest" :key="menu.name">
                            <template slot="title">{{ menu.name }}</template>
                            <el-menu-item v-for="(subMenu, subIndex) in filter(menu.subMenu)" :index="subMenu.dest"
                                          :key="subMenu.dest + subIndex">
                                {{ subMenu.name }}
                            </el-menu-item>
                        </el-submenu>
                    </template>
                </el-menu>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    name: 'widgetNav',
    data: function () {
        return {}
    },
    computed: {
        menuList: function () {
            return this.$store.state.menu
        },
        activeComponent: function () {
            return this.$route.name
        }
    },
    methods: {
        runInto: function (value) {
            this.$router.push({name: value}).catch(error => {
                function doNothing () {
                }

                doNothing(error)
            })
        },
        logout: function () {
            this.utils.postJson(this.$store.state.path.logout).then(data => {
                this.$store.commit('logout')
                this.$router.push({path: '/login'})
            }, e => {
                this.$message.error(e.message || '登出失败')
            })
        },
        filter: function (list) {
            return list.filter(item => !item.hide)
        }
    }
}
</script>

<style scoped>
.logo {
    display: inline-block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    line-height: 60px;
}

.user-dropdown {
    margin-left: 10px;
    float: right;
    cursor: pointer;
    line-height: 60px;
}

.dropdown-text {
    font-weight: bold;
}

.fa-user {
    margin-left: 10px;
}
</style>
