<template>
    <div id="app">
        <el-container direction="vertical">
            <el-header class="app-header" v-if="showNav">
                <widget-nav></widget-nav>
            </el-header>
            <el-main class="app-main">
                <el-row type="flex" justify="center" v-if="showBreadcrumb">
                    <el-col :span="24">
                        <widget-breadcrumb></widget-breadcrumb>
                    </el-col>
                </el-row>
                <router-view></router-view>
            </el-main>
            <el-footer class="app-footer" v-if="showNav">
                <widget-footer></widget-footer>
            </el-footer>
        </el-container>
    </div>
</template>

<script>
import widgetNav from '@/widget/widgetNav.vue'
import widgetBreadcrumb from '@/widget/widgetBreadcrumb.vue'
import widgetFooter from '@/widget/widgetFooter.vue'

export default {
    name: 'App',
    components: {
        widgetNav,
        widgetBreadcrumb,
        widgetFooter
    },
    computed: {
        showNav: function () {
            return this.$route.meta.showNav
        },
        showBreadcrumb: function () {
            return this.$route.meta.showBreadcrumb
        }
    },
}
</script>

<style>
html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    min-width: 315px;
    font-family: '思源黑体', '微软雅黑', '苹方', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

ul {
    padding-inline-start: 24px;
}

#app {
    width: 100%;
    height: 100%;
}

#app > * {
    width: 100%;
    height: 100%;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.text-center {
    text-align: center;
}

.app-header > *,
.app-main > *,
.app-footer > * {
    max-width: 960px;
    margin: 0 auto;
}

.app-header {
    height: 61px !important;
    background-color: white;
    border-bottom: 1px solid #e6e6e6;
}

.app-footer {
    position: static;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto !important;
    background-color: #2c2c32;
    text-align: left;
}

.main-content {
    max-width: 1200px;
    margin: 0 auto;
}

.el-main {
    overflow: initial;
}

.trick {
    padding: 0 6px;
    color: #FFFFFF;
    user-select: none;
}

.trick:hover {
    transition: color .2s linear;
    color: #000000;
}

@media (max-width: 620px) {
    .app-header {
        height: 49px !important;
    }

    .logo {
        font-size: 18px !important;
        line-height: 48px !important;
    }

    .el-menu-item,
    .el-submenu__title {
        padding: 0 10px;
    }

    .el-menu--horizontal > .el-menu-item,
    .el-menu--horizontal > .el-submenu .el-submenu__title {
        height: 48px;
        line-height: 48px;
    }

}

@media (max-width: 480px) {

    .el-menu > li {
        display: none;
    }

    .el-menu > li:nth-child(1),
    .el-menu > li:nth-child(2),
    .el-menu > li:nth-child(3) {
        display: list-item;
    }
}
</style>
