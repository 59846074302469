<template>
    <div id="widget-footer">
        <el-row :gutter="20">
            <el-col :span="24">
                <a class="icp" href="https://beian.miit.gov.cn/" target="_blank">沪ICP备20024943号-1</a>
            </el-col>
        </el-row>
    </div>
</template>

<script>

export default {
    name: 'widgetFooter',
    data: function () {
        return {}
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#widget-footer {
    padding: 12px 0;
    color: #999999;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    line-height: 28px;
}

.icp {
    position: relative;
    color: white;
    text-decoration: none;
}

.icp:after {
    display: none;
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
    height: 20px;
    content: '有空再写得好看一点';
    color: #999999;
    font-size: 12px;
}

.icp:hover:after {
    display: block;
}

.list {
    margin: 35px 15px;
}

ul, li {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
}

.line {
    width: 100px;
    margin: 10px 0;
    border: 1px solid #78aeee;
    background-color: #78aeee
}

ul li {
    line-height: 22px;
    font-size: 12px;
}

ul li a {
    color: white;
    text-decoration: none;
}
</style>
