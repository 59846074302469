import Vue from 'vue'
import ELEMENT from 'element-ui';

import App from './App'
import store from './store'
import router from './router'
import Utils from './Utils'

Vue.config.productionTip = false

Vue.use(Utils)

Vue.use(ELEMENT)

new Vue({
    render: h => h(App),
    store,
    router
}).$mount('#app')
