import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const IS_LOCAL = false
const BASE_PATH = location.origin
const PROJECT_PATH = ''
const VIEW_PATH = BASE_PATH + PROJECT_PATH
const SERVER_PATH = 'https://www.wmada.com'
const API_PATH = IS_LOCAL ? BASE_PATH + PROJECT_PATH : SERVER_PATH + PROJECT_PATH

let localApi = {
    history: '/data/history.json',
    blogList: '/data/blogList.json'
}

let remoteApi = {
    history: '/data/history.json',
    blogList: '/api/blogList'
}

const menu = [{
    name: '主页',
    dest: 'Home',
    path: '/'
}, {
    name: '博客',
    dest: 'BlogList',
    path: '/blog',
    subMenu: [{
        name: '博客详情',
        dest: 'Blog',
        path: '/blog',
        hide: true
    }]
}, {
    name: '更新日志',
    dest: 'History',
    path: '/history'
}, {
    name: '后台管理',
    dest: '',
    path: '',
    hide: false,
    subMenu: [{
        name: '博客管理',
        dest: 'Manage.Upload',
        path: '/manage/upload'
    }]
}]

const page = {
    login: PROJECT_PATH + '/login',
    home: PROJECT_PATH + '/'
}

let store = new Vuex.Store({
    state: {
        isLocal: IS_LOCAL,
        base: VIEW_PATH,
        cors: !API_PATH.startsWith(BASE_PATH),
        apiPath: API_PATH,
        api: IS_LOCAL ? localApi : remoteApi,
        page,
        menu,
        const: {},
        user: getUser(),
        authorization: localStorage.getItem('authorization') || ''
    },
    getters: {
        isLogin: function (state) {
            return state.user && state.user.id
        }
    },
    mutations: {
        login (state, payload) {
            state.user = payload.user
            state.authorization = payload.token
            localStorage.setItem('user', JSON.stringify(payload.user))
            localStorage.setItem('authorization', payload.token)
        },
        logout (state) {
            state.user = {}
            state.authorization = undefined
            localStorage.removeItem('user')
            localStorage.removeItem('authorization')
        }
    }
})

function getUser () {
    return {
        id: 1,
        name: '管理员'
    }
}

export default store
