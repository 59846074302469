<template>
    <div id="widget-breadcrumb">
        <el-row :gutter="20" type="flex" justify="space-between">
            <el-col :span="24">
                <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item v-for="(item, index) in breadcrumbList" :key="index"
                                        :to="pathTo(item)">{{ item.name }}
                    </el-breadcrumb-item>
                </el-breadcrumb>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    name: 'widgetBreadcrumb',
    data: function () {
        return {}
    },
    computed: {
        breadcrumbList () {
            let chain = this.link()

            if (this.$route.name === 'Blog') {
                chain.splice(chain.length - 1, 1)
                if (this.$route.params.folder) {
                    chain.push({name: this.$route.params.folder})
                }
                if (this.$route.params.name) {
                    chain.push({name: this.$route.params.name})
                }
            }

            return chain
        }
    },
    methods: {
        pathTo (route) {
            if (route.dest === this.$route.name) {
                return undefined
            } else if (route.dest) {
                return {
                    path: route.path,
                    query: route.query
                }
            } else {
                return undefined
            }
        },
        link () {
            let chain = []

            function next (dic, route) {
                chain.push(dic)
                if (route.name === dic.dest) {
                    return true
                }
                if (dic.subMenu) {
                    for (let subMenu of dic.subMenu) {
                        if (next(subMenu, route)) {
                            return true
                        }
                    }
                }
                chain.pop()
                return false
            }

            for (let menu of this.$store.state.menu) {
                if (next(menu, this.$route)) {
                    console.log(chain)
                    return chain
                }
            }
            return chain
        }
    }
}
</script>

<style scoped>
.breadcrumb {
    font-size: 14px;
    height: 18px;
    margin-bottom: 20px;
}
</style>
