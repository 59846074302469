import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '../store'

import * as Home from '@/components/home/bundle.js'
import * as History from '@/components/history/bundle.js'
import * as Manage from '@/components/manage/bundle.js'
import * as Error from '@/components/error/bundle.js'
import * as Blog from '@/components/blog/bundle.js'

Vue.use(VueRouter)

let router = new VueRouter({
    mode: 'history',
    routes: [
        {path: '/', name: 'Home', component: Home.Index, meta: {open: true, showNav: true, showBreadcrumb: false}},
        {
            path: '/blog',
            name: 'BlogList',
            component: Blog.BlogList,
            meta: {open: true, showNav: true, showBreadcrumb: false}
        },
        {
            path: '/blog/:name',
            name: 'Blog',
            component: Blog.Blog,
            meta: {open: true, showNav: true, showBreadcrumb: true}
        },
        {
            path: '/blog/:folder/:name',
            name: 'Blog',
            component: Blog.Blog,
            meta: {open: true, showNav: true, showBreadcrumb: true}
        },
        {
            path: '/history',
            name: 'History',
            component: History.Index,
            meta: {open: true, showNav: true, showBreadcrumb: false}
        },
        {
            path: '/manage/upload',
            name: 'Manage.Upload',
            component: Manage.Upload,
            meta: {open: false, showNav: true, showBreadcrumb: true}
        },
        {
            path: '*',
            name: 'Error.notFound',
            component: Error.notFound,
            meta: {open: true, showNav: true, showBreadcrumb: false}
        }
    ]
})

router.beforeEach((to, from, next) => {
    console.log(to)
    if (to.matched.some(record => !record.meta.open)) {
        if (!Store.getters.isLogin) {
            next({
                path: Store.state.page.login,
                query: {redirect: to.fullPath}
            })
            return
        }
    }
    next() // 确保一定要调用 next()
})

router.afterEach((route) => {
    // Vue.$utils.postJson(Store.state.api.log, route.params).then(data => {
    //     console.debug(data)
    // }, error => {
    //     console.warn(error)
    // }).catch(error => {
    //     console.warn(error)
    // })
})

export default router
